var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "c--sim_detail",
    class: `sim-detail theme-${_vm.sim.operator.style}`
  }, [_c('div', {
    staticClass: "sim-detail-header",
    style: `background: linear-gradient(${_vm.$dir() === 'rtl' ? 270 : 90}deg,${_vm.sim.operator.gradient_start} 0,${_vm.sim.operator.gradient_end})`,
    attrs: {
      "data-testid": "sim-detail-header"
    }
  }, [_c('div', {
    class: ['sim-detail-close airalo-icon-close-round-filled tw-text-5.5', _vm.sim.operator.style === 'dark' ? 'tw-text-neutral-600' : 'tw-text-white'],
    on: {
      "click": _vm.closeModal
    }
  }), _vm._v(" "), _c('div', {
    class: ['sim-detail-operator', {
      'tw-flex tw-justify-between tw-items-center tw-w-full': _vm.simHasPromotion
    }],
    attrs: {
      "data-testid": "sim-detail-operator-title"
    }
  }, [_c('p', [_vm._v(_vm._s(_vm.sim.operator.title))]), _vm._v(" "), _c('div', [_vm.simHasPromotion && _vm.sim.is_stock ? [_c('PromotionBadge', {
    attrs: {
      "percentage": _vm.pkgMxn_packagePromotionDiscount
    }
  })] : _vm._e()], 2)]), _vm._v(" "), _c('div', {
    staticClass: "sim-detail-top"
  }, [_c('div', {
    staticClass: "sim-detail-image"
  }, [_vm.sim.operator.image && _vm.sim.operator.image.url ? [_c('img', {
    directives: [{
      name: "lazy-load",
      rawName: "v-lazy-load"
    }],
    attrs: {
      "data-src": _vm.sim.operator.image.url,
      "src": `/assets/images/placeholders/sim-operator-big-${_vm.sim.operator.style === 'dark' ? 'light' : 'dark'}.svg`,
      "width": "335",
      "alt": ""
    }
  })] : [_c('img', {
    attrs: {
      "src": `/assets/images/placeholders/sim-operator-big-${_vm.sim.operator.style === 'dark' ? 'light' : 'dark'}.svg`,
      "width": "335",
      "alt": "",
      "data-not-lazy": ""
    }
  })]], 2), _vm._v(" "), _c('div', {
    staticClass: "sim-detail-info tw-relative"
  }, [_c('ul', {
    staticClass: "sim-detail-info-list",
    attrs: {
      "data-testid": "sim-detail-info-list"
    }
  }, [_vm.sim.freemium && _vm.sim.freemium.is_freemium ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon": "airalo-icon-star",
      "key-text": _vm.$t('components.freemium.welcome-esim'),
      "value": _vm.$t('components.freemium.free')
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon": "airalo-icon-globe-2",
      "key-text": _vm.$t('global.coverage'),
      "value": _vm.sim.operator.type === 'local' ? _vm.sim.operator.country.title : `${_vm.sim.operator.countries.length} ${_vm.$t('global.countries')}`
    }
  })], 1), _vm._v(" "), _vm.sim.fair_usage_policy ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon": "airalo-icon-arrows-up-and-down",
      "key-text": _vm.$t('global.data'),
      "value": _vm.sim.data,
      "is-sub": true,
      "sub-icon": "ic_fup",
      "sub-text": _vm.sim.fair_usage_policy
    }
  })], 1) : _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon": "airalo-icon-arrows-up-and-down",
      "key-text": _vm.$t('global.data'),
      "value": _vm.pkgMxn_packageHasUnlimitedData ? _vm.$t('global.data-unlimited') : _vm.sim.data
    }
  })], 1), _vm._v(" "), _vm.pkgMxn_packageHasDVTVoice ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon-is-svg": true,
      "icon": "ic_calls",
      "key-text": _vm.$t('global.calls'),
      "value": _vm.pkgMxn_packageHasUnlimitedVoice ? _vm.$t('global.calls-unlimited') : _vm.$t('global.minutes', {
        amount: _vm.sim.voice
      })
    }
  })], 1) : _vm._e(), _vm._v(" "), _vm.pkgMxn_packageHasDVTText ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon-is-svg": true,
      "icon": "ic_sms",
      "key-text": _vm.$t('global.texts'),
      "value": _vm.pkgMxn_packageHasUnlimitedText ? _vm.$t('global.texts-unlimited') : _vm.$t('global.sms', {
        amount: _vm.sim.text
      })
    }
  })], 1) : _vm._e(), _vm._v(" "), _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon": "airalo-icon-calendar",
      "key-text": _vm.$t('global.validity'),
      "value": `${_vm.sim.day} ${_vm.dayText(_vm.sim.day)}`,
      "is-last": false
    }
  })], 1), _vm._v(" "), _vm.sim.is_stock ? _c('li', [_c('sim-item-row', {
    attrs: {
      "theme": _vm.sim.operator.style,
      "icon-is-svg": true,
      "icon": "ic_price_v2",
      "key-text": _vm.$t('global.price'),
      "value": _vm.pkgMxn_packageNonPromotedPrice.formatted,
      "is-last": true,
      "is-price": true,
      "price-has-promotion": _vm.simHasPromotion && _vm.sim.is_stock,
      "is-promoted-price": false
    }
  })], 1) : _vm._e()])])])]), _vm._v(" "), _c('div', {
    staticClass: "sim-detail-content",
    class: {
      'bottom-rounded': _vm.isCheckoutByBuyerLocationRestricted
    }
  }, [_c('div', {
    staticClass: "sim-detail-available-packages-wrapper"
  }, [_c('div', {
    staticClass: "sim-detail-available-packages-header",
    attrs: {
      "data-testid": "top-up-header"
    }
  }, [_c('p', [_vm._v("\n          " + _vm._s(_vm.$t('scenes.package.detail.available-top-ups')) + "\n          "), _vm.availablePackages && !!_vm.availablePackages.length ? _c('span', [_vm._v("(" + _vm._s(_vm.availablePackages.length) + ")")]) : _vm._e()])]), _vm._v(" "), _vm.availablePackagesLoader ? [_c('div', {
    staticClass: "relative preloader-min-h-236px"
  }, [_c('preloader', {
    staticClass: "relative",
    attrs: {
      "value": true
    }
  })], 1)] : [!_vm.availablePackages.length ? _c('div', {
    staticClass: "sim-detail-available-packages no-available-packages"
  }, [_c('p', {
    staticClass: "no-available-packages",
    attrs: {
      "data-testid": "no-available-packages-message"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('scenes.topup.list.no-topup-title')) + "\n          ")])]) : _c('div', {
    staticClass: "sim-detail-available-packages"
  }, [_c('CardPackageCarousel', {
    attrs: {
      "main_package": _vm.sim,
      "sim_packages": _vm.availablePackages,
      "sim_isdark": _vm.sim.operator.style === 'dark',
      "sim_gradient_start": _vm.sim.operator.gradient_start,
      "sim_gradient_end": _vm.sim.operator.gradient_end
    }
  })], 1)]], 2), _vm._v(" "), _c('div', {
    staticClass: "d-grid grid-cols-1 grid-cols-sm-2 gap-30 mt-35 mt-sm-40 mb-40"
  }, [_c('div', [_c('country-list', {
    attrs: {
      "title": _vm.$t('components.sim.modal.supported-countries'),
      "list": _vm.sim.operator.countries,
      "is-shadowed-box": true,
      "is-rounded-box": true,
      "search-input-placeholder": _vm.$t('placeholder.supported.countries.search')
    }
  })], 1), _vm._v(" "), _c('div', [_c('additional-information', {
    attrs: {
      "type": _vm.sim.operator.type,
      "title": _vm.$t('scenes.package.detail.aditional-info'),
      "network-list": _vm.sim.operator.type === 'global' ? _vm.globalSimNetworks : _vm.sim.operator.networks,
      "plan-type": _vm.sim.operator.plan_type,
      "activation-policy": _vm.sim.operator.activation_policy,
      "calling-credit": _vm.sim.calling_credit ? _vm.sim.calling_credit : '',
      "e-kyc": !!_vm.sim.operator.is_kyc_verify,
      "e-kyc-restriction": _vm.sim.operator && _vm.sim.operator.kyc_restriction ? _vm.sim.operator.kyc_restriction : null,
      "e-kyc-validation": _vm.sim.operator && _vm.sim.operator.is_kyc_one_time && _vm.sim.operator.kyc_expiry_duration ? _vm.sim.operator.kyc_expiry_duration : null,
      "is-kyc-one-time": !!_vm.sim.operator.is_kyc_one_time,
      "topup-option": _vm.sim.operator.rechargeability,
      "otherInfo": _vm.sim.operator.other_info && _vm.sim.operator.other_info.length > 0 ? _vm.sim.operator.other_info : '',
      "is-shadowed-box": true,
      "is-rounded-box": true,
      "has-routing-section": _vm.hasRoutingSection,
      "is-routing": !!_vm.sim.operator.is_routing
    },
    on: {
      "onOpenNetworksModal": _vm.onOpenNetworksModal
    }
  })], 1)]), _vm._v(" "), _vm.isCheckoutByBuyerLocationRestricted ? _c('WarningV2', {
    attrs: {
      "message": _vm.$t('components.popup.uae-restriction-description')
    }
  }) : _vm.kycRequired() ? _c('KYCRejectedWarning', {
    staticClass: "esim-popup-kyc-rejected-warning",
    attrs: {
      "isGoDetail": _vm.isGoToeKycDetail,
      "translate": _vm.getTranslateWithKYCType,
      "kyc-identity": _vm.kycIdentity
    }
  }) : _vm._e()], 1), _vm._v(" "), !_vm.isCheckoutByBuyerLocationRestricted ? _c('div', {
    staticClass: "sim-detail-bottom",
    class: {
      'tw-promo-prices-bg': _vm.footerHasPromotionBackground
    },
    attrs: {
      "data-testid": "sim-details-footer"
    }
  }, [_vm.isSimQuotaExceeded() ? _c('div', [_c('p', {
    staticClass: "text-center mt-4 mb-0"
  }, [_vm._v("\n        " + _vm._s(_vm.$t('global.kyc.simlimit', {
    count: _vm.sim.operator.country.sim_limit
  })) + "\n      ")])]) : !_vm.$store.state.auth.loggedIn || !_vm.sim.operator.is_kyc_verify || _vm.getKYCType !== 'pending' ? _c('div', {
    staticClass: "tw-flex tw-flex-col sm:tw-flex-row tw-gap-2 sm:tw-gap-7.5",
    class: {
      promoted: _vm.simHasPromotion
    }
  }, [_vm.showLoyaltyReward ? _c('sim-promotion-loyalty-reward', {
    staticClass: "tw-mr-2 tw-flex-nowrap sm:tw-hidden",
    attrs: {
      "item": _vm.sim,
      "text-size": "sm",
      "heading-bold": false
    }
  }) : _vm._e(), _vm._v(" "), _vm.kycRequired() && _vm.isKycRejectedOrExpired ? _c('div', {
    staticClass: "call-to-action dark std-alone-sized-larger btn-primary-hv",
    attrs: {
      "data-testid": "eKYC-button"
    },
    on: {
      "click": _vm.toCompleteKyc
    }
  }, [_vm._v("\n        " + _vm._s(_vm.$t('global.ekyc-procced-resubmit')) + "\n      ")]) : _vm.sim.is_stock ? [_c('div', {
    staticClass: "tw-hidden sm:tw-inline-flex tw-flex-row tw-w-1/2"
  }, [_vm.showLoyaltyReward ? _c('sim-promotion-loyalty-reward', {
    staticClass: "tw-mr-3 rtl:tw-ml-3",
    attrs: {
      "item": _vm.sim,
      "text-size": "sm",
      "heading-bold": false,
      "text-column-orientation": ""
    }
  }) : _vm._e(), _vm._v(" "), _vm.simHasPromotion ? _c('SharedSalePrice', {
    staticClass: "tw-text-white",
    attrs: {
      "discountOrFormattedPrice": _vm.pkgMxn_packagePromotionDiscount,
      "displayIcon": true,
      "isDiscount": true,
      "salePriceEndDateIso": _vm.pkgMxn_packagePromotionEndDateIso,
      "tooltipTargetId": `package-detail-bottom-button-${_vm.sim.id}-desktop`
    }
  }) : _vm._e(), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-items-center tw-grow tw-font-semibold tw-text-6",
    class: _vm.simHasPromotion ? 'tw-text-white tw-justify-end' : 'tw-text-primary tw-justify-start',
    attrs: {
      "data-testid": "esim-detail-package-price"
    }
  }, [_vm._v("\n            " + _vm._s(_vm.pkgMxn_packageCurrentPrice.formatted) + "\n          ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "tw-flex sm:tw-hidden tw-flex-row tw-w-1/2"
  }, [_vm.simHasPromotion ? _c('SharedSalePrice', {
    staticClass: "tw-text-white tw-hidden sm:tw-inline-flex",
    attrs: {
      "discountOrFormattedPrice": _vm.pkgMxn_packageCurrentPrice.formatted,
      "displayIcon": true,
      "isDiscount": false,
      "salePriceEndDateIso": _vm.pkgMxn_packagePromotionEndDateIso,
      "tooltipTargetId": `package-detail-bottom-button-${_vm.sim.id}-mobile`
    }
  }) : _c('div', {
    staticClass: "tw-hidden tw-items-center tw-grow tw-text-7 tw-font-semibold tw-text-primary tw-justify-start sm:tw-flex"
  }, [_vm._v("\n            " + _vm._s(_vm.pkgMxn_packageCurrentPrice.formatted) + "\n          ")])], 1), _vm._v(" "), _c('div', {
    staticClass: "tw-flex tw-w-full tw-gap-2 sm:tw-w-1/2"
  }, [_vm.simHasPromotion ? _c('SharedSalePrice', {
    staticClass: "tw-text-white tw-inline-flex sm:tw-hidden tw-grow tw-shrink-0",
    attrs: {
      "discountOrFormattedPrice": _vm.pkgMxn_packageCurrentPrice.formatted,
      "displayIcon": true,
      "isDiscount": false,
      "salePriceEndDateIso": _vm.pkgMxn_packagePromotionEndDateIso,
      "tooltipTargetId": `package-detail-bottom-button-${_vm.sim.id}-mobile`
    }
  }) : _c('div', {
    staticClass: "tw-flex tw-items-center tw-grow tw-text-7 tw-font-semibold tw-text-primary tw-justify-start sm:tw-hidden tw-shrink-0"
  }, [_vm._v("\n            " + _vm._s(_vm.pkgMxn_packageCurrentPrice.formatted) + "\n          ")]), _vm._v(" "), _c('b-button', {
    staticClass: "topup-package-price-btn tw-font-semibold tw-text-4.5 tw-leading-4 tw-py-4",
    attrs: {
      "block": "",
      "data-testid": "buy-button"
    },
    on: {
      "click": _vm.toBuyESim
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('global.buy')) + "\n          ")])], 1)] : _c('div', {
    staticClass: "call-to-action dark std-alone-sized-larger btn-primary-hv",
    attrs: {
      "data-testid": "notify-stock-button"
    },
    on: {
      "click": _vm.toNotifyESim
    }
  }, [_vm._v("\n        " + _vm._s(_vm.isNotifiedSim ? _vm.$t('scenes.package.detail.cta-button-remove-notify') : _vm.$t('scenes.package.detail.cta-button-notify')) + "\n      ")])], 2) : _vm._e()]) : _vm._e(), _vm._v(" "), _c('client-only', [_c('prompt-modal', {
    attrs: {
      "modal-view": _vm.notifyMeModal,
      "overlay-view": _vm.notifyMeModalOverlay,
      "type": "double",
      "text": _vm.isNotifiedSim ? _vm.$t('components.stock.remove-notification-title') : _vm.$t('components.stock.add-notification-title'),
      "button": {
        text: _vm.$t('global.cancel'),
        callbackName: 'closePromptModal',
        isClose: true
      },
      "button-second": {
        text: _vm.isNotifiedSim ? _vm.$t('components.stock.remove-notification-positive-button') : _vm.$t('components.stock.add-notification-positive-button'),
        callbackName: !_vm.isNotifiedSim ? 'addPackageReminder' : 'removePackageReminder',
        class: _vm.isNotifiedSim ? 'red' : '',
        variant: !_vm.isNotifiedSim ? 'prompt-simple-secondary' : false
      }
    },
    on: {
      "onCloseModal": _vm.closePromptModal,
      "onClickButton": _vm.clickPromptModalActions
    }
  }, [!_vm.isNotifiedSim ? _c('div', {
    staticClass: "mt-10"
  }, [_c('p', {
    staticClass: "outofstock-notify-desc m-none"
  }, [_vm._v("\n          " + _vm._s(_vm.$t('messages.notification.out-of-stock-add')) + "\n        ")]), _vm._v(" "), _c('div', {
    staticClass: "d-flex align-items-center justify-content-start mt-20 mb-20"
  }, [_c('Toggle', {
    staticClass: "v-middle",
    attrs: {
      "value": _vm.notifyEmailToo
    },
    on: {
      "change": function ($event) {
        _vm.notifyEmailToo = arguments[0];
      }
    }
  }), _vm._v(" "), _c('p', {
    class: ['toggle-label m-none', {
      'ml-10': _vm.$dir() === 'ltr',
      'mr-10': _vm.$dir() === 'rtl'
    }],
    on: {
      "click": function ($event) {
        _vm.notifyEmailToo = !_vm.notifyEmailToo;
      }
    }
  }, [_vm._v("\n            " + _vm._s(_vm.$t('components.stock.add-notification-switch-text')) + "\n          ")])], 1)]) : _vm._e()]), _vm._v(" "), _c('KYCVerifyModal'), _vm._v(" "), _vm.sim.operator.type === 'global' ? [_c('NetworksModal', {
    attrs: {
      "modal-id": "sim-global-networks-modal",
      "is-networks-modal": true,
      "modal-view": _vm.networksModalView,
      "countries": _vm.globalSimNetworks,
      "title": _vm.$t('components.supported.countries.modal.network-title'),
      "description": _vm.$t('components.supported.countries.modal.network-description'),
      "search-input-placeholder": _vm.$t('placeholder.supported.countries.modal.network-search'),
      "empty-text": _vm.$t('global.no-country-available')
    },
    on: {
      "onCloseSupportedCountriesModal": _vm.onCloseNetworksModal
    }
  })] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }