<template>
  <div
    class="c--cobranded-sim-loyalty-reward tw-bg-transparent tw-flex tw-justify-start tw-items-center tw-w-full md:tw-w-auto"
    :class="{ 'tw-justify-between': textColumnOrientation }"
  >
    <img v-if="showIcon" :src="logoUrl" alt="Loyalty Logo" class="tw-w-8 tw-h-8 tw-rounded tw-object-contain" />
    <div
      class="loyalty-reward-text tw-flex tw-justify-between tw-items-center tw-w-full tw-flex-nowrap tw-flex-grow"
      :class="{ 'tw-flex-col !tw-items-start !tw-justify-start tw-gap-y-1': textColumnOrientation }"
    >
      <span
        :class="[
          fontColourClass,
          {
            'tw-font-semibold': headingBold,
            'tw-font-normal': !headingBold,
            'tw-text-[.65rem] tw-leading-[1.125rem]': isTextSizeSm,
            'tw-text-[.75rem] tw-tracking-[.0625rem]': isTextSizeMd,
            'tw-text-[.8125rem] tw-leading-[1.125rem]': isTextSizeLg,
          },
        ]"
      >
        {{ headingText }}
      </span>
      <span
        class="tw-font-semibold"
        :class="[
          fontColourClass,
          {
            'tw-font-semibold': rewardTextBold,
            'tw-font-normal': !rewardTextBold,
            'tw-text-[.875rem] tw-leading-[1.375rem]': isTextSizeSm,
            'tw-text-[1rem] tw-leading-[.875rem]': isTextSizeMd,
            'tw-text-[1.125rem] tw-leading-[1.375rem] -tw-tracking-[.0125rem]': isTextSizeLg,
          },
        ]"
      >
        {{ rewardText }}
      </span>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    props: {
      item: {
        type: Object,
        required: true,
      },
      textColumnOrientation: {
        type: Boolean,
      },
      textSize: {
        type: String,
        default: 'md',
        validator: (value) => ['sm', 'md', 'lg'].includes(value),
      },
      headingText: {
        type: String,
        default: 'Estimated Reward',
      },
      headingBold: {
        type: Boolean,
        default: true,
      },
      rewardTextBold: {
        type: Boolean,
        default: true,
      },
      showIcon: {
        type: Boolean,
        default: true,
      },
      fontColourClass: {
        type: String,
        default: 'tw-text-primary',
      },
      rewardOverride: {
        type: Number,
        default: 0,
      },
    },

    computed: {
      ...mapGetters({
        isCoBranded: 'coBranding/getIsCoBranded',
        branding: 'coBranding/getBranding',
        currentCurrencyCode: 'multiCurrency/get_current_currency_code',
      }),

      logoUrl() {
        return this.branding?.loyalty_logo ?? this.branding?.primary_logo
      },

      calculatedReward() {
        if (this.rewardOverride) {
          return this.rewardOverride
        }

        if (!this.branding?.loyalty_program_usd_conversion || !this.item?.price?.minor_amount) {
          return 0
        }

        return this.branding?.loyalty_program_usd_conversion * (this.item?.price?.minor_amount / 100)
      },

      rewardText() {
        if (this.rewardOverride) {
          return `${this.rewardOverride?.toFixed(0)} ${this.branding?.loyalty_program_unit}`
        }

        return `${this.calculatedReward?.toFixed(0)} ${this.branding?.loyalty_program_unit}`
      },

      isTextSizeSm() {
        return this.textSize.toLowerCase() === 'sm'
      },

      isTextSizeMd() {
        return this.textSize.toLowerCase() === 'md'
      },

      isTextSizeLg() {
        return this.textSize.toLowerCase() === 'lg'
      },
    },
  }
</script>

<style lang="scss" scoped>
  img {
    margin-inline-start: 0.625rem;
    margin-inline-end: 0.625rem;
  }

  .loyalty-reward-text {
    margin-inline-start: auto;
    margin-inline-end: auto;
  }
</style>
